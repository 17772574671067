import clsx from 'clsx';
import type { GetStaticProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useMemo, useState } from 'react';

import {
  GetFeaturedActivity,
  GetUserActivity,
  type Activity,
  type TransactionAsActivity,
  type UserActivity,
} from '@endaoment-frontend/activities';
import {
  GetAllPortfolios,
  GetCommunityFundsCount,
  GetDonationsCount,
  GetFeaturedFunds,
  GetFeaturedOrgs,
  GetFundsCount,
  GetOnboardedCounts,
  GetRecommendationsMadeByMe,
  GetRecommendationsMadeForMe,
  GetTotalDonations,
  GetTotalGrants,
  GetUserCollaboratedFunds,
  GetUserFunds,
  GetUserIdentity,
} from '@endaoment-frontend/api';
import { useAccountDisplayName, useAuth, useAuthType, useWalletModal } from '@endaoment-frontend/authentication';
import type { StoredTransaction } from '@endaoment-frontend/blockchain-interactions';
import { useTransactionList } from '@endaoment-frontend/blockchain-interactions';
import { featureFlags } from '@endaoment-frontend/config';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import { makeDehydratedQueries } from '@endaoment-frontend/data-fetching';
import { useOpenDonationWizard, type OpenWizardOptions } from '@endaoment-frontend/donation-wizard';
import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import { useEnsNameWithFallback, UserAvatar } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { type Address } from '@endaoment-frontend/types';
import {
  AllocationIcon,
  CardIcon,
  ExploreActionIcon,
  InfoIcon,
  LogoTextIcon,
  MigrateIcon,
  NewFundIcon,
} from '@endaoment-frontend/ui/icons';
import { ActionButton, Button, buttonClassNames, Loader } from '@endaoment-frontend/ui/shared';
import { arrToSorted, equalAddress, formatShortAddress } from '@endaoment-frontend/utils';

import { ActivitySection } from '../components/ActivitySection';
import { PendingRecommendations } from '../components/PendingRecommendations';
import { ReferralCodeCard } from '../components/ReferralCodeCard';
import { FundsSection, ProfileEditModal } from '../content/dashboard';

import type { AppProps } from './_app.page';
import styles from './index.module.scss';

export const getStaticProps: GetStaticProps<AppProps> = async () => {
  const seo: AppProps['seo'] = {
    title: 'Endaoment - Welcome!',
  };
  try {
    return {
      props: {
        dehydratedState: await makeDehydratedQueries(
          GetFeaturedFunds.getDehydratedQueryArgs([]),
          GetFeaturedOrgs.getDehydratedQueryArgs([]),
          GetTotalDonations.getDehydratedQueryArgs([]),
          GetAllPortfolios.getDehydratedQueryArgs([]),
          GetOnboardedCounts.getDehydratedQueryArgs([]),
          GetCommunityFundsCount.getDehydratedQueryArgs([]),
          GetDonationsCount.getDehydratedQueryArgs([]),
          GetFundsCount.getDehydratedQueryArgs([]),
          GetTotalGrants.getDehydratedQueryArgs([]),
          GetFeaturedActivity.getDehydratedQueryArgs([]),
        ),
        seo,
      },
      // ISR every 5 minutes
      revalidate: 5 * TIME_ONE_MINUTE_IN_SECONDS,
    };
  } catch {
    // On fetch errors, we still want to allow the build to continue, we will reattempt to fetch the data on the client
    return {
      props: {
        seo,
      },
      revalidate: 1,
    };
  }
};

export const convertStoredTransactionToActivity = (transaction: StoredTransaction): TransactionAsActivity => ({
  ...transaction,
  transactionHash: transaction.hash,
  transactionType: transaction.type,
  transactor: transaction.wallet,
  occurredAtUtc: transaction.createdAt,
  type: 'transaction',
  automated: false,
});
const getRenderableActivities = (
  address: Address,
  activities: Array<Activity>,
  transactions: Array<StoredTransaction>,
) => {
  const map = new Map<Address, UserActivity>();
  const extras: Array<Activity> = [];

  for (const tx of transactions) {
    if (!equalAddress(tx.wallet, address) || tx.status === 'success' || tx.type === 'DEPLOY_FUND') continue;
    map.set(tx.hash, convertStoredTransactionToActivity(tx));
  }
  for (const activity of activities) {
    if ('transactionHash' in activity && activity.transactionHash) map.set(activity.transactionHash, activity);
    else extras.push(activity);
  }

  const allActivities = [...Array.from(map.values()), ...extras];
  // Compare activity by occurred at date, most recent first
  const sortedActivities = arrToSorted(allActivities, (a, b) => b.occurredAtUtc - a.occurredAtUtc);
  return sortedActivities;
};
export const useRenderableActivityList = (address: Address) => {
  const { data: myActivities = [], isPending } = GetUserActivity.useQuery([]);
  const { allTransactions: localTransactions } = useTransactionList();

  const renderableActivities = useMemo(
    (): Array<UserActivity> =>
      getRenderableActivities(address, myActivities, Array.from(localTransactions.values()).reverse().slice(0, 10)),
    [address, localTransactions, myActivities],
  );

  return {
    renderableActivities,
    isPendingActivities: isPending,
  };
};

const ActionButtonRow = ({ hasFunds }: { hasFunds: boolean }) => {
  const { isSignedIn } = useAuth();
  const openDonationWizard = useOpenDonationWizard();
  const openFundWizard = useOpenFundWizard();

  const { data: userFunds } = GetUserFunds.useQuery([], {
    enabled: isSignedIn,
  });

  const addAssetsDestination: OpenWizardOptions =
    userFunds && userFunds?.length === 1 ? { initialRecipient: { id: userFunds[0].id, type: 'fund' } } : {};

  if (!isSignedIn || !hasFunds) {
    return (
      <>
        <h5 className={styles['action-buttons__label']}>Get Started</h5>
        <div className={styles['action-buttons']}>
          <ActionButton
            text='Start a Fund'
            subtext='Completely free to setup, we only need your name and address'
            onClick={() => openFundWizard({ initialMode: 'create' })}
            color='fund'
            className={clsx(styles['action-button'], styles['action-button__spinny'])}>
            <NewFundIcon width={34} color='currentColor' fillOpacity='1' />
          </ActionButton>
          <ActionButton
            text='Migrate a DAF'
            subtext='Move a portion of funds or your entire DAF from another provider'
            onClick={() => openFundWizard({ initialMode: 'migrate' })}
            color='purple'
            className={styles['action-button']}>
            <MigrateIcon width={36} color='currentColor' />
          </ActionButton>
          <ActionButton
            text='Learn about the app'
            subtext='Find out more about our features and how to use them'
            as={Link}
            href={routes.marketing.donors()}
            color='org'
            className={styles['action-button']}
            target='_blank'>
            <InfoIcon width={36} color='currentColor' fillOpacity='1' />
          </ActionButton>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles['action-buttons']}>
        <ActionButton
          text='Donate Assets'
          subtext='Contribute to your fund via Credit Card, Crypto, Brokerage, and more'
          onClick={() => openDonationWizard(addAssetsDestination)}
          color='fund'
          className={styles['action-button']}>
          <CardIcon width={34} color='currentColor' fillOpacity='1' />
        </ActionButton>
        <ActionButton
          text='Allocate Assets'
          subtext='Move your DAF assets into a variety of flexible portfolio options'
          as={Link}
          href={routes.app.portfolios()}
          color='violet'
          className={styles['action-button']}>
          <AllocationIcon width={38} color='currentColor' />
        </ActionButton>
        <ActionButton
          text='Make a Grant'
          subtext='Support causes you care about with instant grants'
          onClick={() => openDonationWizard({ initialMode: 'grant' })}
          color='org'
          className={styles['action-button']}>
          <ExploreActionIcon width={36} color='currentColor' />
        </ActionButton>
      </div>
    </>
  );
};

// Code path for when user is authed
const DashboardPage = ({ address }: { address: Address }) => {
  const { isWalletAuth } = useAuthType();
  const { shortAccountName } = useAccountDisplayName();

  const { data: ensName } = useEnsNameWithFallback({ address });

  const [showProfileEdit, setShowProfileEdit] = useState(false);

  const { data: userInfo } = GetUserIdentity.useQuery([]);
  const { data: managedFunds } = GetUserFunds.useQuery([]);
  const { data: collaboratedFunds } = GetUserCollaboratedFunds.useQuery([]);
  const { data: recommendationsForMe } = GetRecommendationsMadeForMe.useQuery([]);
  const { data: recommendationsByMe } = GetRecommendationsMadeByMe.useQuery([]);

  // The user is a collaborator as long as 1+ funds are not managed by them,
  // implying they are returned from can `GetUserFunds` because they are a collaborator
  const isCollaborator = !!collaboratedFunds && collaboratedFunds.length > 0;
  const funds = managedFunds && collaboratedFunds ? [...managedFunds, ...collaboratedFunds] : undefined;
  const recommendations = arrToSorted(
    recommendationsByMe && recommendationsForMe ? [...recommendationsByMe, ...recommendationsForMe] : [],
    (r1, r2) => (r1.createdAt > r2.createdAt ? -1 : 1),
  );

  const { renderableActivities, isPendingActivities } = useRenderableActivityList(address);

  const hasFunds = !!managedFunds && managedFunds.length > 0;

  return (
    <>
      <Head>
        <title>Dashboard - Endaoment</title>
      </Head>
      <div className={styles['top-spacer']}>
        <div className={styles['top-spacer__content']}>
          <div>
            <h1>Your Dashboard</h1>
            <p>See your funds and activity.</p>
          </div>
          <div className={styles['user-card-container']}>
            <UserAvatar address={address} className={styles['user-avatar']} />
            <h6 className={styles['wallet-info']}>
              {shortAccountName}
              {!!userInfo?.email && <p>{userInfo.email}</p>}
              {(!!isWalletAuth || !!ensName) && <p>{formatShortAddress(address)}</p>}
            </h6>
            <div className={styles['user-actions']}>
              <Button
                size='small'
                filled
                variation='fund'
                onClick={() => setShowProfileEdit(true)}
                className={styles['user-actions__button']}>
                Edit Profile
              </Button>
              {!featureFlags.disableMembership && (
                <Link
                  className={clsx(
                    buttonClassNames.base,
                    buttonClassNames.sizes.small,
                    buttonClassNames.filled,
                    buttonClassNames.variants.purple,
                    buttonClassNames.float,
                    styles['user-actions__button'],
                  )}
                  target='_blank'
                  href={routes.app.membership()}>
                  Membership
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <ActionButtonRow hasFunds={hasFunds} />
      <div className={styles['container']}>
        <div>
          <FundsSection
            funds={funds}
            displayStats={!isCollaborator}
            isCollaboratorOnFund={fund => (collaboratedFunds || []).some(f => f.id === fund.id)}
            className={styles['funds-section']}
          />
        </div>

        <div>
          {!!recommendations && recommendations.length > 0 && (
            <PendingRecommendations
              recommendations={recommendations}
              location='dashboard'
              isExecutable={recommendation =>
                (managedFunds || []).some(f => f.id === recommendation.collaboratorFund.id)
              }
            />
          )}

          <ActivitySection
            className={styles['activities-section']}
            activities={renderableActivities}
            subject='user'
            isLoading={isPendingActivities}
          />
          <ReferralCodeCard />
        </div>
      </div>

      {!!userInfo && (
        <ProfileEditModal isOpen={showProfileEdit} onClose={() => setShowProfileEdit(false)} userInfo={userInfo} />
      )}
    </>
  );
};

const LockedDashboard = ({ isLoading }: { isLoading: boolean }) => {
  const { showWallet } = useWalletModal();

  return (
    <>
      <div className={styles['locked-overlay']}>
        <div className={clsx(styles['locked-overlay__inner'], isLoading && styles['locked-overlay__inner--loading'])}>
          {isLoading ? (
            <Loader size='l' />
          ) : (
            <>
              <Button onClick={() => showWallet()} filled variation='purple' size='medium'>
                Sign In
              </Button>
              <h2>Connect via email, social account or wallet to get started.</h2>
              <Link href={routes.marketing.home()} target='_blank'>
                <LogoTextIcon color='currentColor' />
              </Link>
            </>
          )}
        </div>
      </div>
      <div className={styles['top-spacer']}>
        <div className={styles['top-spacer__content']}>
          <h1>
            Your <b>Dashboard</b>
          </h1>
        </div>
      </div>

      <ActionButtonRow hasFunds={false} />

      <div className={styles['container']}>
        <div>
          <FundsSection
            funds={[]}
            displayStats={false}
            isCollaboratorOnFund={() => false}
            className={styles['funds-section']}
          />
        </div>

        <div>
          <ActivitySection className={styles['activities-section']} activities={[]} subject='user' isLoading={false} />
        </div>
      </div>
    </>
  );
};

// Initial Load has to direct user
const DashboardPagePreAuth = () => {
  const { authAddress, isSignedIn, isLoading } = useAuth();

  return (
    <div className={styles.outer} key={authAddress}>
      {!isSignedIn || !authAddress ? (
        <LockedDashboard isLoading={isLoading} />
      ) : (
        <DashboardPage key={authAddress} address={authAddress} />
      )}
    </div>
  );
};

export default DashboardPagePreAuth;
